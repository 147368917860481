import Image from 'next/image';

import { EthereumIcon } from '@endaoment-frontend/ui/icons';

import type { MediaCards } from './components/MediaCarousel';
import type { TestimonialCards } from './components/TestimonialCarousel';
import type { BoardMembers, Roles, TeamMembers } from './content/about';
import type { AdvisorTestimonials } from './content/advisors';
import type { PortfolioAllocationCards } from './content/donors/PortfolioAllocations';
import type { FAQs } from './content/learn/FaqSection';
import type { Timeline } from './content/ndao';
import carter from './images/avatars/board/carter.png';
import maggie from './images/avatars/board/maggie.png';
import robbie from './images/avatars/board/robbie.png';
import ruth from './images/avatars/board/ruth.png';
import vance from './images/avatars/board/vance.png';
import holly from './images/avatars/ext/holly-circle.png';
import alexis from './images/avatars/team/alexis.png';
import bruxa from './images/avatars/team/bruxa.png';
import dae from './images/avatars/team/dae.png';
import ishaan from './images/avatars/team/ishaan.png';
import noah from './images/avatars/team/noah.png';
import pedro from './images/avatars/team/pedro.png';
import pietro from './images/avatars/team/pietro.jpg';
import ricardo from './images/avatars/team/ricardo.png';
import zach from './images/avatars/team/zach.png';
import aaveLogo from './images/logos/aave-logo.svg';
import blocksterlogo from './images/logos/blockster.png';
import coindesklogo from './images/logos/coindesk.png';
import compoundLogo from './images/logos/compound-logo.svg';
import computerCowgirlsLogo from './images/logos/computerCowgirls.png';
import cryptoaltruismlogo from './images/logos/cryptoaltruism.png';
import decentiallogo from './images/logos/decential.png';
import decryptlogo from './images/logos/decrypt.png';
import forkastlogo from './images/logos/forkast.svg';
import fundTexasChoiceLogo from './images/logos/fundTexasChoiceLogo.png';
import nftEveningLogo from './images/logos/nft-evening-logo.png';
import nonprofittimeslogo from './images/logos/nonprofittimes.png';
import yahoofinancelogo from './images/logos/yahoo-finance.png';

export const TESTIMONIAL_SLIDES: TestimonialCards = [
  {
    logo: <Image src={fundTexasChoiceLogo} alt='Fund Texas Choice' width={128} height={32} />,
    quote:
      '“Endaoment has been an absolute game changer for Fund Texas Choice. When we decided that holding an organizational crypto-wallet was not for us, **we were thrilled to find Endaoment as the perfect solution.**”',
    name: 'Jaylynn Farr Munson',
    from: 'Fund Texas Choice',
    href: 'https://app.endaoment.org/orgs/46-3372095',
  },
  {
    logo: <Image src={computerCowgirlsLogo} alt='Computer Cowgirls' width={128} height={32} />,
    quote:
      "“**Endaoment has been a pivotal partner** in COWGIRLDAO's efforts to raise money for abortion access. Our donor-advised fund is both transparent and efficient in donating to 501c3 organizations that are unfamiliar with crypto.”",
    name: 'Molly Dickson',
    from: 'Computer Cowgirls',
    href: 'https://www.computercowgirls.io/cowgirldao',
  },
  {
    quote:
      '“Endaoment has been a great organization to work with. They are engaging, transparent and innovative. I have learned so much from their staff and the content they put out... **there is so much more to it than just crypto.**”',
    name: 'Carlos',
    from: 'Endaoment Donor',
  },
  {
    quote:
      '“Endaoment is making a difference everyday — **demonstrating blockchain innovation and its powerful impact**, especially for those who need help urgently.”',
    name: 'Holly Maddox',
    logo: <Image src={holly} alt='Holly Maddox, Circle' width={50} height={50} />,
    from: 'Circle',
    href: 'https://circle.com',
  },
  {
    quote:
      '“Their **novel technology** and **dependable customer service** allow us to convert a variety of assets into our fund, manage and grow the impact of those assets, and deliver grants to the organizations that we aim to support.”',
    name: 'Karl',
    from: 'Endaoment Donor',
  },
] as const;

export const MEDIA_SLIDES: MediaCards = [
  // 2023 articles
  {
    source: <Image src={nonprofittimeslogo} alt='The Nonprofit Times' width={128} height={32} />,
    title: 'Leveraging Blockchain for  Transparency',
    snippet:
      'Blockchain technology has the potential to revolutionize the nonprofit sector by providing transparency, efficiency, and security, to help nonprofits achieve their goals and serve their communities.',
    date: 'September 25, 2023',
    href: 'https://thenonprofittimes.com/technology/leveraging-blockchain/',
  },
  {
    source: <Image src={cryptoaltruismlogo} alt='Crypto Altruism' width={128} height={32} />,
    title: 'How to Support Maui Wildfire Relief Efforts with Cryptocurrency',
    snippet: 'Cryptocurrency donations offer a fast and efficient way to support wildfire relief efforts in Maui.',
    date: 'August 14, 2023',
    href: 'https://www.cryptoaltruism.org/blog/how-to-support-maui-wildfire-relief-efforts-with-cryptocurrency',
  },
  {
    source: <Image src={blocksterlogo} alt='Blockster' width={128} height={32} />,
    title: 'The Endaoment-Etherscan Partnership Revolutionizes Crypto Philanthropy',
    snippet:
      'The Endaoment and Etherscan partnership is bringing much-needed transparency and accountability to the world of crypto philanthropy.',
    date: 'June 23, 2023',
    href: 'https://blockster.com/fostering-transparency-and-accountability-endaoment-etherscan-partnership-in-crypto-philanthropy',
  },
  {
    source: <Image src={cryptoaltruismlogo} alt='Crypto Altruism' width={128} height={32} />,
    title: 'Philanthropy meets Quadratic Funding',
    snippet:
      'Endaoment and Gitcoin are partnering to bring the power of quadratic funding to the world of crypto philanthropy, maximizing the impact of their donations through quadratic funding.',
    date: 'July 17, 2023',
    href: 'https://www.cryptoaltruism.org/blog/crypto-philanthropy-meets-quadratic-funding-in-new-endaoment-gitcoin-partnership',
  },
  {
    source: <Image src={decentiallogo} alt='DeCential' width={128} height={32} />,
    title: 'Endaoment Is Converting All Types of Digital Assets Into Philanthropy',
    snippet:
      'Through transparency and accountability, Endaomet is setting the bar for crypto-philanthropy and accept all kinds of digital assets.',
    date: 'June 7, 2023',
    href: 'https://www.decential.io/articles/endaoment-is-converting-all-types-of-digital-assets-into-philanthropy',
  },
  {
    source: <Image src={coindesklogo} alt='CoinDesk' width={128} height={32} />,
    title: 'Endaoment Expands Web3 Charitable Fundraising Efforts With GlobalGiving Partnership',
    snippet:
      'This partnership has tripled the number of verified charitable organizations willing to accept digital assets worldwide.',
    date: 'May 16, 2023',
    href: 'https://www.coindesk.com/web3/2023/05/16/endaoment-expands-web3-charitable-fundraising-efforts-with-globalgiving-partnership/',
  },

  {
    source: <Image src={nftEveningLogo} alt='NFT Evening' width={128} height={32} />,
    title: 'Crypto Meets Film Funding: “I’M NEW HERE” Initiative',
    snippet:
      'The "I\'m New Here" initiative is a groundbreaking example of how the worlds of crypto and film can come together for a common goal.',
    date: 'March 17, 2023',
    href: 'https://nftevening.com/crypto-meets-film-funding-in-endaoments-im-new-here-initiative/',
  },
  {
    source: <Image src={yahoofinancelogo} alt='Yahoo Finance' width={128} height={32} />,
    title: 'Sotheby’s Hosts International Womens’  Art Auction',
    snippet:
      "Sotheby's, Endaoment, and Unicorndao are teaming up to celebrate International Women's Day with a special NFT auction. The auction will feature works by female creators and support women's empowerment organizations.",
    date: 'March 7, 2023',
    href: 'https://finance.yahoo.com/news/sotheby-unicorndao-host-international-womens-022120747.html?guccounter=1',
  },
  {
    source: <Image src={coindesklogo} alt='CoinDesk' width={128} height={32} />,
    title: 'Crypto Philanthropy 101: What Donors and Organizations Need to Know',
    snippet:
      'Giving crypto as a charitable donation or setting up a nonprofit to receive crypto isn’t hard, but there are some unique considerations to think about.',
    date: 'May 5, 2023',
    href: 'https://www.coindesk.com/learn/crypto-philanthropy-101-what-donors-and-organizations-need-to-know/',
  },
  {
    source: <Image src={decryptlogo} alt='Decrypt' width={128} height={32} />,
    title: 'Charitable Crypto Giving Continues Through Bear Market',
    snippet:
      "Cryptocurrency offers exciting potential for humanitarian aid - as the world reels from ever-increasing natural disasters, it's time to seriously consider the role of crypto in disaster relief.",
    date: 'March 4, 2023',
    href: 'https://decrypt.co/122729/crypto-charity-donations-humanitarian-disaster-aid-relief',
  },
  {
    source: <Image src={forkastlogo} alt='Forkast' width={128} height={32} />,
    title: 'Crypto Growing Pains. What’s Coming Next?',
    snippet:
      'Cryptocurrency is experiencing growing pains, but the future remains bright. Regulation, scalability and UX are all challenges that the industry must address in order to reach its full potential.',
    date: 'February 27, 2023',
    href: 'https://forkast.news/crypto-is-experiencing-growing-pains-what-lies-ahead/',
  },
  {
    source: <Image src={decryptlogo} alt='Decrypt' width={128} height={32} />,
    title: 'Millions in Crypto Donations Pour Into Turkey Following Devastating Earthquakes',
    snippet: 'Mobilizing crypto philanthropy to drive humanitarian aid for earthquakes in Turkey.',
    date: 'February 7, 2023',
    href: 'https://decrypt.co/120770/turkey-syria-earthquake-relief-cryptocurrency-fundraising',
  },
] as const;

export const FAQS: FAQs = [
  {
    title: 'At-a-glance: Demystifying Endaoment',
    questions: [
      {
        question: 'Why should I donate through Endaoment?',
        answer: `Endaoment offers a seamless, onchain platform for making charitable contributions using a diverse range of assets—cryptocurrencies, stocks, fiat currencies, real estate, and more. By leveraging blockchain transparency, every transaction is publicly verifiable via [Etherscan](https://etherscan.io/). [Learn how](https://www.cointracker.io/blog/crypto-tax-deduction-donor-advised-funds) Endaoment can help you maximize tax benefits while contributing to worthy causes.`,
      },
      {
        question: 'What are donor-advised funds?',
        answer: `Think of a donor-advised fund as a 'charitable checking account.' Operated by a 501(c)(3) organization, you deposit assets into this fund, receive an instant tax receipt, and then you can direct these assets to various eligible nonprofits. [Explore the tax implications](https://www.nptrust.org/what-is-a-donor-advised-fund/daf-tax-consideration/).`,
      },
      {
        question: "What does 'direct donation' mean?",
        answer: `A direct donation is a one-time or recurring financial gift made directly to an organization's profile on Endaoment's platform.`,
      },
      {
        question: 'What are community funds?',
        answer: `Community funds are collective fundraising initiatives that anyone can contribute to. These funds are managed based on predetermined criteria or through community-driven voting mechanisms.`,
      },
      {
        question: 'Are there any fees?',
        answer: `We charge a [tiered fee](https://docs.endaoment.org/donors/resources/fee-schedule#tiered-inbound-fee) on incoming donations and 1% on outgoing grants to sustain our operations. [Learn about our fee structure](https://docs.endaoment.org/donors/resources/fee-schedule).`,
      },
      {
        question: 'What about security audits?',
        answer: `Endaoment's robust security infrastructure has undergone rigorous audits by [LeastAuthority](https://leastauthority.com/) and [OpenZeppelin](https://openzeppelin.com/). You can review our [V2 public audit report](https://leastauthority.com/static/publications/LeastAuthority_Endaoment_V2_Smart_Contracts_Final_Audit_Report.pdf).`,
      },
    ],
  },
  {
    title: 'Donors: How-To Guide',
    questions: [
      {
        question: 'What types of assets can I donate?',
        answer: `Endaoment accommodates a diverse range of assets—cryptocurrencies, stocks, fiat currencies, real estate, and more. If you have a bespoke request, please reach out to us at admin@endaoment.org.`,
      },
      {
        question: 'How do I create a donor-advised fund?',
        answer: `Starting a new fund is as simple as logging in and selecting 'Start a new Fund.' [Learn the steps](https://docs.endaoment.org/donors/getting-started/opening-a-fund).`,
      },
      {
        question: 'Can I contribute to any nonprofit?',
        answer: `Absolutely, you can donate to any U.S.-based 501(c)(3) nonprofit organization.`,
      },
      {
        question: 'Can I donate without using the app?',
        answer: `Yes, we offer an over-the-counter donation options. Learn more (LINK MISSING) or email us at admin@endaoment.org.`,
      },
      {
        question: 'Are international donations possible?',
        answer: `Endaoment supports contributions to compliant U.S. 501(c)(3) as well as qualified international organizations through our partnership with US nonprofit GlobalGiving.`,
      },
      {
        question: 'What constitutes a donation?',
        answer: `A donation is a final, non-refundable contribution of an asset to Endaoment. [Know more](https://docs.endaoment.org/donors/getting-started/donating-to-a-fund).`,
      },
      {
        question: "What's a grant recommendation?",
        answer: `This is your advisory input, directing Endaoment to allocate funds to your chosen nonprofit. [Learn the procedure](https://docs.endaoment.org/donors/getting-started/recommending-grants).`,
      },
      {
        question: 'What is a portfolio position?',
        answer: `It represents your invested assets within a specific fund. [Read about our investment policies](https://docs.endaoment.org/donors/getting-started/recommending-grants).`,
      },
      {
        question: 'What are my tax obligations?',
        answer: `For donations over $500, you'll need a tax receipt and [IRS Form 8283](https://www.irs.gov/pub/irs-pdf/f8283.pdf) to claim any potential deduction.`,
      },
    ],
  },
  {
    title: 'Organizations: Your next steps',
    questions: [
      {
        question: 'Is Endaoment a registered nonprofit?',
        answer: `Yes, we operate as a U.S. Public Charity. Read [our mission](https://docs.endaoment.org/governance/documentation/mission-values).`,
      },
      {
        question: 'How can my organization receive granted funds?',
        answer: `Funds can be withdrawn to either your bank account or your cryptocurrency wallet. [Learn the procedure](https://docs.endaoment.org/organizations/grants-and-claims/claim-your-organization).`,
      },
      {
        question: "How do I claim my organization's profile?",
        answer: `Navigate to your profile on Endaoment and select 'Is this your organization?'. [Here's how](https://docs.endaoment.org/organizations/grants-and-claims/claim-your-organization).`,
      },
      {
        question: 'How do I accept grants?',
        answer: `You can accept grants via a simple email confirmation.`,
      },
      {
        question: "Can I modify my organization's displayed data?",
        answer: `Yes, we source data from GuideStar and GlobalGiving, but we offer manual adjustments. Contact us at [admin@endaoment.org](mailto:admin@endaoment.org) for modifications.`,
      },
    ],
  },
] as const;

// TODO: All items are missing optional URL props
export const CAREERS: Roles = [{ title: 'Mid Level Front End Web Dev', description: 'Remote' }] as const;

export const BOARD_MEMBERS: BoardMembers = [
  {
    name: 'Robbie Heeger',
    position: 'President & CEO',
    description: 'Founded Endaoment in 2019. Experienced consumer technology operator & builder.',
    avatar: robbie,
  },
  {
    name: 'Carter Wilkinson',
    position: 'Treasurer & Secretary',
    description: 'Startup veteran and operational expert focused on entity architecture and legal compliance.',
    avatar: carter,
  },
  {
    name: 'Ruth Bender',
    position: 'Director',
    description: 'Philanthropy and donor-advised fund expert with two decades of domain specialization.',
    avatar: ruth,
  },
  {
    name: 'Vance Spencer',
    position: 'Director',
    description: 'GP & Founder of Framework Ventures providing value and insight into global crypto adoption.',
    avatar: vance,
  },
  {
    name: 'Maggie Love',
    position: 'Director',
    description: 'SheFi Founder, formerly with W3bCloud and ConsenSys, driving inclusivity in emerging technologies.',
    avatar: maggie,
  },
] as const;

export const TEAM_MEMBERS: TeamMembers = [
  {
    name: 'Robbie Heeger',
    position: 'President & Chief Executive Officer',
    avatar: robbie,
    location: 'New York, NY',
  },
  {
    name: 'Zach Bronstein',
    position: 'Chief Operating Officer',
    avatar: zach,
    location: 'New York, NY',
  },
  {
    name: 'Ricardo Pedroni',
    position: 'Chief Technology Officer',
    avatar: ricardo,
    location: 'Curitiba, Brazil',
  },
  {
    name: 'Dae Moyer',
    position: 'Growth and Marketing Operations',
    avatar: dae,
    location: 'Los Angeles, CA',
  },
  {
    name: 'Noah Gallant',
    position: 'Product & Design Lead',
    avatar: noah,
    location: 'New York, NY',
  },
  {
    name: 'Alexis Miller',
    position: 'Director of Development',
    avatar: alexis,
    location: 'Washington, D.C.',
  },
  {
    name: 'Pedro Yan Ornelas',
    position: 'Principal Engineer',
    avatar: pedro,
    location: 'Brasilia, Brazil',
  },
  {
    name: 'Ishaan Bharal',
    position: 'Frontend Engineer',
    avatar: ishaan,
    location: 'Dallas, TX',
  },
  {
    name: 'Bruxa',
    position: 'Growth and Marketing Producer',
    avatar: bruxa,
    location: 'Durham, NC',
  },
  {
    name: 'Pietro Bertarini',
    position: 'Backend Engineer',
    avatar: pietro,
    location: 'Brasilia, Brazil',
  },
] as const;

export const PORTFOLIO_ALLOCATION_SLIDES: PortfolioAllocationCards = [
  {
    name: 'Ethereum',
    logo: <EthereumIcon width={52} height={67} color='#ADBACC' strokeColor='#ADBACC' />,
    type: 'Single Token - ETH',
    description:
      'Ethereum is a global, open-source platform for decentralized applications. In other words, the vision is to create a world computer that anyone can build applications in a decentralized manner; while all states and data are distributed and publicly accessible. ',
  },
  {
    name: 'Compound',
    logo: <Image src={compoundLogo} width={52} height={67} alt='Compound' />,
    type: 'Stablecoin Yield - cUSDC',
    description:
      'The Compound interest rate market runs on the Ethereum blockchain. When Endaoment supplies USDC to the Compound Protocol, they begin earning a variable interest rate instantly.',
  },
  {
    name: 'AAVE',
    logo: <Image src={aaveLogo} width={52} height={67} alt='AAVE' />,
    type: 'Stablecoin Yield - aUSDC',
    description:
      'The AAVE protocol uses smart contracts to facilitate loans of crypto assets through a network of borrowers and sellers. Depositors earn a variable interest rate on their loans.',
  },
] as const;

// TODO: Replace with real content
export const RELEASE_TIMELINE: Timeline = [
  {
    label: "July '22",
    detail:
      'The Endaoment Protocol v2, including the NDAO token contract, completes external audit by leading researchers at Least Authority.',
  },
  {
    label: "Aug '22",
    detail:
      'Endaoment announces the NDAO token, system architecture, initial distribution and airdrop of tokens to the Endaoment community.',
  },
  {
    label: "Dec '22",
    detail: 'NDAO Token Launch and initial airdrop to users based on their contribution to the Endaoment community.',
  },
  {
    label: "March '23",
    detail:
      'Endaoment.Org holds elections during their annual meeting. Users select new Directors via snapshot voting and NVT ownership.',
  },
  {
    label: 'Ongoing',
    detail:
      '12-week Benefit Cycles release new NDAO & USDC token rewards to NVT holders based on activity and participation.',
  },
] as const;

/* TODO: Add financial advisors testimonials */
export const ADVISOR_TESTIMONIALS: AdvisorTestimonials = [] as const;
